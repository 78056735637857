<template>
    <div v-if="loading" class="page-center">
        <div class="page-center-box text-center">
            <ui-loading-page />
        </div>
    </div>

    <div v-else class="page-inner">

        <div class="page-title">Договоры</div>
        <div class="hr"></div>

        <div class="alert alert-info my-4">
            Отрицательные суммы в колонке "Задолженность" означают переплату, а положительные - задолженности.
        </div>


        <div class="search-filter mt-4">
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter(null)" class="search-filter__link"
                    :loading="loadingFilter && !filter" :disabled="loadingFilter" :class="!filter && 'active'">
                    Все <span v-if="total !== 0">({{ total }})</span>
                </ui-button>
            </div>
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter('act')" class="search-filter__link"
                    :loading="loadingFilter && filter === 'act'" :disabled="loadingFilter"
                    :class="filter === 'act' && 'active'">
                    Действующие <span v-if="actTotal !== 0">({{ actTotal }})</span>
                </ui-button>
            </div>
            <div class="search-filter__item">
                <ui-button :white="true" @click="setFilter('closing')" class="search-filter__link"
                    :loading="loadingFilter && filter === 'closing'" :disabled="loadingFilter"
                    :class="filter === 'closing' && 'active'">
                    Закрытые <span v-if="closingTotal !== 0">({{ closingTotal }})</span>
                </ui-button>
            </div>

            <div v-if="debtTotal !== 0" class="search-filter__item">
                <ui-button :white="true" @click="setFilter('debt')" class="search-filter__link"
                    :loading="loadingFilter && filter === 'debt'" :disabled="loadingFilter"
                    :class="filter === 'debt' && 'active'">
                    С задолженностью <span v-if="debtTotal !== 0">({{ debtTotal }})</span>
                </ui-button>
            </div>
        </div>


        <div v-if="contracts.length === 0" class="position-relative no-list-data">
            <ui-no-data-page text="Список договоров пуст" />
        </div>

        <div v-else>

            <!-- <div class="alert alert-info mt-4 mb-0">
                Отрицательные суммы в колонке задолженность это переплата, положительные - долг!
            </div> -->

            <div class="scroll table-responsive mt-4">
                <table class="table mb-0">
                    <thead>
                        <tr class="text-nowrap">
                            <th>ДЛ</th>
                            <th>Дата заключения</th>
                            <th>Предмет лизинга</th>
                            <th style="width: 182px;">Дата следующего платежа</th>
                            <th class="text-end" style="width: 193px;">Сумма следующего платежа</th>
                            <th class="text-end">Сумма</th>
                            <th class="text-end">Задолженность</th>
                            <th>Статус</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, index in contracts">
                            <td class="text-nowrap">
                                <RouterLink @click="clickScroll()" :to="'/contracts/' + item.dl"> {{ item.dl }}
                                </RouterLink>
                            </td>
                            <td class="text-nowrap">{{ dateMask(item.date) }}</td>
                            <td class="text-truncate" style="max-width: 350px;" :title="item.item">{{ item.item }}</td>
                            <td class="text-nowrap">{{ item.nextData ? dateMask(item.nextData) : '&#8212;' }}</td>
                            <td class="text-nowrap text-end">{{ item.nextSum ? moneyMask(item.nextSum) : '&#8212;' }}
                            </td>
                            <td class="text-nowrap text-end">{{ moneyMask(item.sum) }}</td>
                            <td class="text-nowrap text-end">{{ moneyMask(item.debt) }}</td>
                            <td class="text-nowrap">{{ item.status }}</td>
                            <td class="text-end text-nowrap">
                                <RouterLink @click="clickScroll()" class="btn btn-base btn-small"
                                    :to="'/contracts/' + item.dl">Подробнее
                                </RouterLink>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div v-if="!pageBtn && total > contracts.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

            <div v-if="pageBtn === 'act' && actTotal > contracts.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

            <div v-if="pageBtn === 'closing' && closingTotal > contracts.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

            <div v-if="pageBtn === 'debt' && debtTotal > contracts.length" class="text-center mt-4">
                <ui-button @click="getPage" :loading="loadingPage" class="btn-ghost">Загрузить еще</ui-button>
            </div>

        </div>
    </div>
</template>


<script>
import { getApi, dateMask, moneyMask } from '@/run'

import { mapMutations, mapGetters } from 'vuex'

export default {
    name: 'contracts.contracts',
    data() {
        return {
            loading: false,
            loadingFilter: false,
            loadingPage: false,
            filter: 'act',
            pageBtn: 'act',
            contracts: [],
            total: 0,
            actTotal: 0,
            closingTotal: 0,
            debtTotal: 0,
            dateMask: dateMask,
            moneyMask: moneyMask
        }
    },
    components: {},
    created() {
        document.title = "Договоры - STONE-XXI"

        this.start()
    },
    computed: {
        ...mapGetters({
            getContracts: 'page/getContracts',
            getFilter: 'page/getFilter',
            getPageBtn: 'page/getPageBtn',
            getScroll: 'page/getScroll'
        })
    },
    methods: {
        ...mapMutations({
            setContracts: 'page/setContracts',
            setFilterCash: 'page/setFilter',
            setPageBtn: 'page/setPageBtn',
            setScroll: 'page/setScroll'
        }),
        start() {

            this.loading = true

            if (this.getContracts.length === 0) {
                getApi('/contracts', {
                    filter: this.filter
                }).then(response => {


                    if (response.status) {
                        this.contracts = response.contracts
                        this.total = response.total
                        this.actTotal = response.actTotal
                        this.closingTotal = response.closingTotal
                        this.debtTotal = response.debtTotal
                        this.setContracts(response)
                    }

                    this.loading = false
                })
            } else {
                this.filter = this.getFilter
                if (this.getContracts.status) {
                    this.contracts = this.getContracts.contracts
                    this.total = this.getContracts.total
                    this.actTotal = this.getContracts.actTotal
                    this.closingTotal = this.getContracts.closingTotal
                    this.debtTotal = this.getContracts.debtTotal
                    this.pageBtn = this.getPageBtn

                    setTimeout(() => window.scrollTo(0, this.getScroll), 0)

                    console.log(this.getScroll);
                }


                this.loading = false


            }

        },
        setFilter(value) {
            this.filter = value
            this.loadingFilter = true
            this.setFilterCash(this.filter)
            getApi('/contracts', {
                filter: value
            }).then(response => {


                if (response.status) {
                    this.contracts = response.contracts
                    this.pageBtn = value
                    this.setPageBtn(value)
                    this.setContracts(response)
                    this.setScroll(0)
                }

                this.loadingFilter = false

            })
        },
        getPage() {
            this.loadingPage = true

            getApi('/contracts', {
                filter: this.filter,
                offset: this.contracts.length
            }).then(response => {


                if (response.status) {
                    this.contracts = [...this.contracts, ...response.contracts]
                    this.setContracts({ ...this.getContracts, contracts: this.contracts })
                }

                this.loadingPage = false

            })
        },
        clickScroll() {
            this.setScroll(window.scrollY)
        }
    }
}
</script>